import Vue from 'vue';
import { format } from 'date-fns';
import Vue2Filters from 'vue2-filters';

Vue.use(Vue2Filters);

Vue.filter('formatDate', (value) => {
    if (!value || value == '') return '';
    const [ year, month, day ] = value.substr(0, 10).split('-');
    const d = new Date(year, (month - 1), day);
    return format(d, 'MM/dd/yyyy');
});

Vue.filter('formatDateTime', (value) => {
    const d = new Date(value);
    return format(d, 'MM/dd/yyyy h:mm:ss a');
});

Vue.filter('last', (value, cnt) => {
    if (!value) {
        return '';
    }
    if (value.length < cnt) {
        return value;
    }
    return value.substring(-cnt);
});

