import axios from 'axios';


const url = '/rates';
const optionUrl = '/rateEntityOptions';

export default {
    create(data) {
        return axios.post(url, data);
    },

    read(id) {
        return axios.get(`${url}/${id}`);
    },

    update(data) {
        return axios.put(`${url}/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${url}/${id}`);
    },

    index(query) {
        return axios.get(url, {
            params: query
        });
    },

    fetchEntityOptions(query) {
        return axios.get(optionUrl, {
            params: query
        });
    }

};
