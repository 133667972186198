// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
        theme: {
            options: {customProperties: true},
            themes: {
                light: {
                    primary: '#102341',
                    secondary: '#1B8393',
                    accent: '#B0A789',
                    error: '#D37C2B',
                    info: '#B69575',
                    success: '#4CAF50',
                    warning: '#D37C2B',
                }
            },
        },
    }
);

export default vuetify;
