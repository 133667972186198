import Vue from 'vue';
import VuetifyConfirm from 'vuetify-confirm';
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import store from '@/store';
import router from '@/router';
import App from './App.vue';
import '@/plugins/filters';
import '@/plugins/movabledialogs';
import '@/plugins/axios';
import '@/plugins/exceleditor';
import '@/plugins/konva';
import pinia from '@/store/pinia';

Vue.use(VuetifyConfirm, {vuetify});
Vue.use(pinia);
new Vue({
    store,
    router,
    vuetify,
    render: h => h(App),
}).$mount('#app');
