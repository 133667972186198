import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import {createStore} from 'vuex-extensions';

import accumulator from './accumulator';
import apikey from './apikey';
import bulkrate from './bulkrate';
import claim from './claim';
import coverage from './coverage';
import customer from './customer';
import customerentity from './customerentity';
import dashboard from './dashboard';
import demomember from './demomember';
import dependent from './dependent';
import documentcategory from "./documentcategory";
import employer from './employer';
import entitydocument from './entitydocument';
import entitymodule from './entitymodule';
import explanation from './explanation';
import fieldmap from './fieldmap';
import input from './input';
import invoice from './invoice';
import job from './job';
import division from './division';
import member from './member';
import modifier from './modifier';
import modifieradjustment from './modifieradjustment';
import module from './module';
import modulecategory from './modulecategory';
import network from "./network";
import operation from './operation';
import output from './output';
import placeofservice from './placeofservice';

import plan from './plan';
import planicon from './planicon';
import planinfo from './planinfo';
import planinfoitem from './planinfoitem';
import plandesign from './plandesign';
import procedurecategory from "./procedurecategory";
import procedurecollection from "./procedurecollection";
import processor from './processor';
import provider from './provider';
import rate from './rate';
import reader from './reader';

import schedule from './schedule';

import schedulegroup from './schedulegroup';
import schedulerun from './schedulerun';
import service from './service';
import servicehistoryfile from './servicehistoryfile';
import sourcefile from './sourcefile';
import subscription from './subscription';
import permission from './permission';
import role from './role';
import tier from './tier';
import taxonomy from './taxonomy';
import tieritem from './tieritem';
import trigger from './trigger';
import tpa from './tpa';
import user from './user';
import usermanagement from './usermanagement';
import writer from './writer';
import diagnosiscode from "./diagnosiscode";

const vuexLocal = new VuexPersistence({
    storage: sessionStorage,
    key: 'cerberus',
    modules: [
        'accumulator',
        'apikey',
        'bulkrate',
        'claim',
        'coverage',
        'customer',
        'customerentity',
        'dashboard',
        'demomember',
        'dependent',
        'documentcategory',
        'entitymodule',
        'fieldmap',
        'input',
        'invoice',
        'job',
        'module',
        'modulecategory',
        'permission',
        'plandesign',
        'role',
        'output',
        'placeofservice',
        'plan',
        'planinfoitem',
        'provider',
        'procedurecategory',
        'procedurecollection',
        'network',
        'rate',
        'reader',
        'schedule',
        'processor',
        'schedulegroup',
        'service',
        'sourcefile',
        'tier',
        'tieritem',
        'trigger',
        'tpa',
        'user',
        'usermanagement',
        'writer',
    ],
});

Vue.use(Vuex);

const store = createStore(Vuex.Store, {
    modules: {
        accumulator,
        apikey,
        bulkrate,
        claim,
        coverage,
        customer,
        customerentity,
        dashboard,
        demomember,
        dependent,
        diagnosiscode,
        documentcategory,
        employer,
        entitydocument,
        entitymodule,
        explanation,
        fieldmap,
        input,
        invoice,
        job,
        division,
        member,
        modifier,
        modifieradjustment,
        module,
        modulecategory,
        network,
        operation,
        output,
        permission,
        placeofservice,
        plan,
        planicon,
        planinfo,
        planinfoitem,
        plandesign,
        procedurecategory,
        procedurecollection,
        processor,
        provider,
        rate,
        reader,
        role,
        schedule,
        schedulegroup,
        schedulerun,
        service,
        servicehistoryfile,
        subscription,
        sourcefile,
        taxonomy,
        tier,
        tieritem,
        trigger,
        tpa,
        user,
        usermanagement,
        writer,
    },
    plugins: [vuexLocal.plugin]
});

export default store;
export const useStore = () => store
