export default [
    {
        icon: 'mdi-home',
        title: 'Home',
        link: '/',
        children: [],
    },

    {
        icon: 'mdi-home',
        title: 'Entities',
        children: [
            {
                icon: 'mdi-domain',
                title: 'Tpas',
                link: '/tpas',
                children: [],
            },
            {
                icon: 'mdi-office-building',
                title: 'Employers',
                link: '/employers',
                children: [],
            },
            {
                icon: 'mdi-account',
                title: 'Members',
                link: '/members',
                children: [],
            },
        ],
        role: 'Entity Management'
    },

    {
        icon: 'mdi-dolphin',
        title: 'DataFlow',
        link: '',
        children: [
            {
                icon: 'mdi-calendar-blank-multiple',
                title: 'Schedules',
                link: '/schedules',
                children: [],
            },
            {
                icon: 'mdi-gesture-tap-button',
                title: 'Triggers',
                link: '/triggers',
                children: [],
            },
            {
                icon: 'mdi-blender',
                title: 'Processors',
                link: '/processors',
                children: [],
            },

            {
                icon: 'mdi-database-import',
                title: 'Readers',
                link: '/readers',
                children: [],
            },
            {
                icon: 'mdi-database-export',
                title: 'Writers',
                link: '/writers',
                children: [],
            },
            {
                icon: 'mdi-import',
                title: 'Inputs',
                link: '/inputs',
                children: [],
            },
            {
                icon: 'mdi-export',
                title: 'Outputs',
                link: '/outputs',
                children: [],
            },

            {
                icon: 'mdi-file-multiple',
                title: 'Source Files',
                link: '/sourcefiles',
                children: [],
            },
            {
                icon: 'mdi-folder',
                title: 'Files',
                link: '/files',
                children: [],
            },
        ],
        role: 'Data Flow Management'
    },

    // {
    //     icon: 'mdi-id-card',
    //     title: 'ID Cards',
    //     link: '/cardtemplates',
    //     children: [
    //         {
    //             icon: 'mdi-plus',
    //             title: 'New Card Template',
    //             link: '/cardtemplate/0',
    //             children: [],
    //         },
    //     ],
    // },

    {
        icon: 'mdi-cash-multiple',
        title: 'Finance',
        link: '/finance/customers',
        children: [],
        role: 'Invoicing'
    },

    {
        icon: 'mdi-controller',
        title: 'Operations',
        link: '/operations/home',
        children: [],
        role: 'Operations'
    },

    {
        icon: 'mdi-notebook',
        title: 'Plan Design',

        children: [
            {
                icon: 'mdi-notebook',
                title: 'Plan Designs',
                link: '/plandesigns',
            },
            {
                icon: 'mdi-toolbox',
                title: 'Service Category Templates',
                link: '/servicecategorytemplates',
            },
            {
                icon: 'mdi-toolbox-outline',
                title: 'Procedure Codes',
                link: '/procedurecodes',
            },
            {
                icon: 'mdi-code-array',
                title: 'Diagnosis Codes',
                link: '/diagnosiscodes',
            },
            {
                icon: 'mdi-code-braces-box',
                title: 'Taxonomy Codes',
                link: '/taxonomies',
            },
            {
                icon: 'mdi-code-block-brackets',
                title: 'Modifiers',
                link: '/modifiers',
            },
            {
                icon: 'mdi-code-brackets',
                title: 'Modifier Adjustments',
                link: '/modifieradjustments',
            },
            {
                icon: 'mdi-map-marker-multiple',
                title: 'Places of Service',
                link: '/placesofservice',
            },
            {
                icon: 'mdi-note-text',
                title: 'Explanations',
                link: '/explanations',
            },
        ],
        role: 'Plan Design'
    },

    {
        icon: 'mdi-cog',
        title: 'Settings',
        children: [
            {
                icon: 'mdi-account',
                title: 'Users',
                link: '/settings/users',
            },
            {
                icon: 'mdi-room-service',
                title: 'Services',
                link: '/services',
                children: [],
            },
            {
                icon: 'mdi-sort-variant',
                title: 'Tiers',
                link: '/tiers',
            },
            {
                icon: 'mdi-key-chain',
                title: 'API Keys',
                link: '/apikeys',
            },
            {
                icon: 'mdi-shape',
                title: 'App Module Categories',
                link: '/settings/modulecategories',
            },
            {
                icon: 'mdi-view-module-outline',
                title: 'App Modules',
                link: '/settings/modules',
            },

            {
                icon: 'mdi-shape',
                title: 'Document Categories',
                link: '/settings/documentcategories',
            },
        ],
        role: 'Super Admin'
    },
];

