import api from '@/api/procedurecategory';

const getDefaultState = () => {
    return {
        all: [],
    };
};

function findItem(id, arr) {
    return arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === id) return item;
        if (item.children) return findItem(id, item.children);
    }, null);
}

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {},

    actions: {
        reset({commit}) {
            commit('RESET');
        },
        index({commit}, query) {
            api.index(query).then(({data}) => {
                commit('INDEX', data);
            });
        },
        fetchChildren({commit}, id) {
            api.index({parent_id: id}).then(({data}) => {
                commit('FETCHCHILDREN', data);
            });
        },
        read({commit}, id) {
            api.read(id).then(({data}) => {
                commit('READ', data);
            });
        },

        create({commit}, formData) {
            return api.create(formData).then(({data}) => {
                commit('READ', data);
                return data;
            });
        },
        update({commit}, formData) {
            api.update(formData).then(({data}) => {
                commit('READ', data);
            });
        },
        delete({commit}, id) {
            api.delete(id).then(() => {
                commit('DELETE', id);
            });
        },

    },

    mutations: {
        RESET(state) {
            Object.assign(state, getDefaultState());
        },

        INDEX(state, responseData) {
            state.all = responseData;
        },

        FETCHCHILDREN(state, childItems) {
            childItems.forEach((childItem) => {
                const parent = findItem(childItem.parent_id, state.all);
                const category = findItem(childItem.procedure_category_id, state.all);
                
                if (parent) {
                    if (!parent.children) {
                        parent.children = [];
                    }
                    parent.children.push(childItem);
                }

                if (category) {
                    if (!category.children) {
                        category.children = [];
                    }
                    category.children.push(childItem);
                }
            });

        },

        READ(state, responseData) {

            const idx = state.all.findIndex(a => a.id == responseData.id);

            if (idx >= 0) {

                state.all.splice(idx, 1, responseData);
            } else {

                state.all.push(responseData);
            }
        },
        DELETE(state, id) {
            const idx = state.all.findIndex(a => a.id == id);
            if (!idx >= 0) {
                state.all.splice(idx, 1);
            }
        },

    }

};
