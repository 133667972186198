import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {get} from 'lodash-es';
import diagnosiscode from "../store/diagnosiscode";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Home.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue'),
        meta: {
            auth: false
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Logout.vue'),
        meta: {
            auth: false
        },
    },

    {
        path: '/tpas',
        name: 'TPAs',
        component: () => import(/* webpackChunkName: "tpas" */ '@/views/Tpas.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/tpas/:itemId',
        name: 'TPA',
        component: () => import(/* webpackChunkName: "tpas" */ '@/views/Tpa.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/employers',
        name: 'Employers',
        component: () => import(/* webpackChunkName: "employers" */ '@/views/Employers.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/employers/:itemId',
        name: 'Employer',
        component: () => import(/* webpackChunkName: "employers" */ '@/views/Employer.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/members',
        name: 'Members',
        component: () => import(/* webpackChunkName: "members" */ '@/views/Members.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/members/:itemId',
        name: 'Member',
        component: () => import(/* webpackChunkName: "members" */ '@/views/Member.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/claims/:memberId/:claimNumber',
        name: 'Claim',
        component: () => import(/* webpackChunkName: "claims" */ '@/views/Claim.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/plans/:planId',
        name: 'Plan',
        component: () => import(/* webpackChunkName: "plans" */ '@/views/Plan.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/services',
        name: 'Services',
        component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/readers',
        name: 'Readers',
        component: () => import(/* webpackChunkName: "readers" */ '@/views/Readers.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/readers/:readerId',
        name: 'Reader',
        component: () => import(/* webpackChunkName: "readers" */ '@/views/Reader.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/writers',
        name: 'Writers',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/Writers.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/writers/:writerId',
        name: 'Writer',
        component: () => import(/* webpackChunkName: "writers" */ '@/views/Writer.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/inputs',
        name: 'Inputs',
        component: () => import(/* webpackChunkName: "inputs" */ '@/views/Inputs.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/inputs/:inputId',
        name: 'Input',
        component: () => import(/* webpackChunkName: "inputs" */ '@/views/Input.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/outputs',
        name: 'Outputs',
        component: () => import(/* webpackChunkName: "outputs" */ '@/views/Outputs.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/outputs/:outputId',
        name: 'Output',
        component: () => import(/* webpackChunkName: "outputs" */ '@/views/Output.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/schedules',
        name: 'Schedules',
        component: () => import(/* webpackChunkName: "schedules" */ '@/views/Schedules.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/schedules/:scheduleId',
        name: 'Schedule',
        component: () => import(/* webpackChunkName: "schedules" */ '@/views/Schedule.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/processors',
        name: 'Processors',
        component: () => import(/* webpackChunkName: "processors" */ '@/views/Processors.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/processors/:processorId',
        name: 'Processor',
        component: () => import(/* webpackChunkName: "processors" */ '@/views/Processor.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/triggers',
        name: 'Triggers',
        component: () => import(/* webpackChunkName: "triggers" */ '@/views/Triggers.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/triggers/:triggerId',
        name: 'Trigger',
        component: () => import(/* webpackChunkName: "triggers" */ '@/views/Trigger.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/sourcefiles',
        name: 'SourceFiles',
        component: () => import(/* webpackChunkName: "sourcefiles" */ '@/views/SourceFiles.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/sourcefile/:fileId',
        name: 'SourceFile',
        component: () => import(/* webpackChunkName: "sourcefiles" */ '@/views/SourceFile.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/settings/users',
        name: 'SettingsUsers',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Users.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/modules',
        name: 'SettingsModules',
        component: () => import(/* webpackChunkName: "modules" */ '@/views/Modules.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/modulecategories',
        name: 'SettingsModuleCategories',
        component: () => import(/* webpackChunkName: "modules" */ '@/views/ModuleCategories.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/settings/documentcategories',
        name: 'SettingsDocumentCategories',
        component: () => import(/* webpackChunkName: "modules" */ '@/views/DocumentCategories.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/tiers',
        name: 'Tiers',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Tiers.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/apikeys',
        name: 'APIKeys',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/ApiKeys.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/tiers/:tierId',
        name: 'Tier',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Tier.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/files',
        name: 'Files',
        component: () => import(/* webpackChunkName: "files" */ '@/views/Files.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/cardtemplate/:itemId',
        name: 'idCardTemplate',
        component: () => import(/* webpackChunkName: "idcards" */ '@/views/CardEditor.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/cardtemplates',
        name: 'idCardTemplates',
        component: () => import(/* webpackChunkName: "idcards" */ '@/views/CardTemplates.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/finance/home',
        name: 'financeHome',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/FinanceHome.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/finance/customers',
        name: 'financeCustomers',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Customers.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/finance/customers/:customerId',
        name: 'financeCustomer',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Customer.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/finance/customerrates/:customerId',
        name: 'financeCustomerRates',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/CustomerRates.vue'),
        meta: {
            auth: true
        },
        props: true,
    },
    {
        path: '/operations/home',
        name: 'operationsHome',
        component: () => import(/* webpackChunkName: "operations" */ '@/views/Operations.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/plandesigns',
        name: 'planDesigns',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/PlanDesigns.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/plandesign/:itemId',
        name: 'planDesign',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/PlanDesign.vue'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/servicecategorytemplates',
        name: 'serviceCategoryTemplates',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/ServiceCategoryTemplates'),
        meta: {
            auth: true
        },
    },

    {
        path: '/servicecategorytemplate/:itemId',
        name: 'serviceCategoryTemplate',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/ServiceCategoryTemplate'),
        meta: {
            auth: true
        },
        props: true,
    },

    {
        path: '/modifiers',
        name: 'modifiers',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/Modifiers'),
        meta: {
            auth: true
        },
    },
    {
        path: '/modifieradjustments',
        name: 'modifierAdjustments',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/ModifierAdjustments'),
        meta: {
            auth: true
        },
    },
    {
        path: '/modifieradjustment/:itemId',
        name: 'modifieradjustment',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/ModifierAdjustment'),
        meta: {
            auth: true
        },
        props: true
    },
    {
        path: '/taxonomies',
        name: 'taxonomies',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/Taxonomies'),
        meta: {
            auth: true
        },
    },
    {
        path: '/placesofservice',
        name: 'placesOfService',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/PlacesOfService'),
        meta: {
            auth: true
        },
    },
    {
        path: '/explanations',
        name: 'explanations',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/Explanations'),
        meta: {
            auth: true
        },
    },

    {
        path: '/diagnosiscodes',
        name: 'DiagnosisCodes',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/DiagnosisCodes'),
        meta: {
            auth: true
        },
    },

    {
        path: '/procedurecodes',
        name: 'ProcedureCodes',
        component: () => import(/* webpackChunkName: "plandesign" */ '@/views/ProcedureCodes'),
        meta: {
            auth: true
        },
    },


];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !get(store, 'state.user.authenticated', false)) {
        next({name: 'Login'});
    } else {
        next();
    }
});

export default router;
